import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isBlockquote,
  isHeading,
  isParagraph,
  render,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container, Heading, Grid } from "@theme-ui/components"
import Layout from "../components/layout"
import { getReportagePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import Breadcrumbs from "../components/breadcrumbs"
import Embed from "../components/blocks/articleEmbed"
import PlaylistThumb from "../components/playlistThumb"
import PlaylistHeading from "../components/playlistHeading"
import { i18nContext } from "../context/i18nContext"

const Reportage = ({ data: { page } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getReportagePath(page, path.locale),
    }
  })

  return (
    <Layout
      pageSlug={page.channel.pageLink.slug}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
              <html lang={page.locale} />
            </HelmetDatoCms>
            <Container sx={{ pb: [0, 0] }}>
              <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
                <Breadcrumbs page={page} />
              </Box>
            </Container>

            <Container sx={{ mb: [8] }}>
              <Grid
                columns={["1fr", "1fr", "1fr", ".5fr .5fr"]}
                sx={{ backgroundColor: "primary", minHeight: "450px" }}
                gap={[0]}
              >
                <Box sx={{ py: [4, 5], px: [3, 5] }}>
                  <Heading as="h1" variant="h2" sx={{ color: "light" }}>
                    {page.title}
                  </Heading>
                </Box>
                {page.heroImage && (
                  <Box
                    sx={{
                      ".gatsby-image-wrapper": {
                        width: "100%",
                        height: "100%",
                      },
                      height: "100%",
                      "& img": {
                        objectFit: "cover !important",
                      },
                    }}
                  >
                    <GatsbyImage
                      image={page.heroImage.gatsbyImageData}
                      style={{ height: "100%" }}
                    />
                  </Box>
                )}
              </Grid>
            </Container>

            <Container
              variant={page.playlist ? "container" : "md"}
              sx={{ mb: 8 }}
            >
              <Grid
                columns={page.playlist ? [1, 1, ".35fr .75fr"] : [1]}
                gap={[8]}
              >
                {page.playlist && (
                  <Box>
                    <Box>
                      <Box sx={{ mb: [5] }}>
                        <PlaylistHeading
                          title={t.playlist}
                          channelSlug={page.channel.pageLink.slug}
                        />
                      </Box>
                      <PlaylistThumb
                        playlist={page.playlist}
                        locale={page.locale}
                      />
                    </Box>
                  </Box>
                )}
                <Box>
                  {page.meta && page.meta.firstPublishedAt && (
                    <Text
                      as="p"
                      sx={{
                        fontSize: [1],
                        letterSpacing: "0.09",
                        lineHeight: "1",
                        fontWeight: "500",
                        mb: 4,
                        color: "#999999",
                      }}
                    >
                      {t.publishedAt}: {page.meta.firstPublishedAt}
                    </Text>
                  )}
                  <StructuredText
                    data={page.body}
                    customRules={[
                      renderRule(
                        isHeading,
                        ({ adapter: { renderNode }, node, children, key }) => {
                          return renderNode(
                            () => {
                              return (
                                <Text
                                  as={`h${node.level}`}
                                  variant={`h${node.level}`}
                                  sx={{ mb: [3, 4], mt: [5, 6] }}
                                >
                                  {children}
                                </Text>
                              )
                            },
                            { key },
                            children
                          )
                        }
                      ),
                      renderRule(
                        isParagraph,
                        ({ adapter: { renderNode }, node, children, key }) => {
                          return renderNode(
                            () => {
                              return (
                                <Text as="p" mb={3} variant="reportage">
                                  {children}
                                </Text>
                              )
                            },
                            { key },
                            children
                          )
                        }
                      ),
                      renderRule(
                        isBlockquote,
                        ({ adapter: { renderNode }, node, children, key }) => {
                          return renderNode(
                            () => {
                              return (
                                <Box
                                  as="blockquote"
                                  sx={{
                                    my: [6, 7],
                                    p: {
                                      fontSize: [5, 6],
                                      lineHeight: "body",
                                      fontWeight: "500",
                                      strong: {
                                        fontWeight: "500",
                                        color: "primary",
                                      }
                                    },
                                  }}
                                >
                                  {children}
                                </Box>
                              )
                            },
                            { key },
                            children
                          )
                        }
                      ),
                    ]}
                    renderBlock={({ record }) => {
                      switch (record.__typename) {
                        case "DatoCmsImageGallery":
                          return (
                            <Box mt={5} mb={5}>
                              <ImageGallery
                                images={record.images}
                                key={record.id}
                              />
                            </Box>
                          )
                        case "DatoCmsEmbed":
                          return (
                            <Embed
                              title={record.title}
                              code={record.code}
                              fullWidth={record.fullWidth}
                            />
                          )
                        default:
                          return null
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Layout>
  )
}

export default Reportage

export const query = graphql`
  query ReportageQuery($id: String!, $locale: String!, $channelId: String!) {
    page: datoCmsReportage(id: { eq: $id }) {
      ...ReportageDetails
      ...ReportageAllSlugLocales
      ...ReportageMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      playlist {
        ...ReportagePlaylist
      }
    }
    newsletterSection: allDatoCmsNewsletterSection(
      filter: {
        channel: { pageLink: { id: { eq: $channelId } } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        subtitle
        link {
          ... on DatoCmsExternalLink {
            id
            anchor
            url
            model {
              apiKey
            }
          }
        }
      }
    }
    sponsorSection: allDatoCmsSponsorSection(
      filter: { channel: { pageLink: { id: { eq: $channelId } } } }
    ) {
      nodes {
        sponsors {
          name
          image {
            url
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          url
        }
      }
    }
  }
`
