import React, { useState } from "react"
import { Box, Text, AspectRatio, Flex, Heading } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink } from "./link"
import { getVideoPath } from "../utils/path"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { List, PlayCircle } from "react-feather"
import { i18nContext } from "../context/i18nContext"

const PlaylistThumb = ({ playlist }) => {
  const firstVideo = playlist.videos.length > 0 ? playlist.videos[0] : null
  const secondVideo = playlist.videos.length > 1 ? playlist.videos[1] : null
  const thirdVideo = playlist.videos.length > 2 ? playlist.videos[2] : null
  const videoCount = playlist.videos.length
  const [showHover, SetShowHover] = useState(false)
  const light = getColor(themeUiTheme, "light")

  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ position: "relative" }}>
          {[secondVideo, thirdVideo].map(
            (video, index) =>
              video && (
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    top: index === 0 ? "-20px" : "-10px",
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    transform: `scale(${index === 0 ? 0.9 : 0.95})`,
                  }}
                >
                  <AspectRatio ratio={16 / 9}>
                    <GatsbyImage
                      image={video.thumbnail.mobile}
                      alt={video.title}
                    />
                  </AspectRatio>
                </Box>
              )
          )}
          <InboundLink
            to={getVideoPath(firstVideo, firstVideo.locale, playlist.originalId)}
            rel="nofollow"
            sx={{
              position: "relative",
              display: "block",
              textDecoration: "none",
              color: "text",
              zIndex: 2
            }}
            onMouseOver={() => {
              SetShowHover(true)
            }}
            onMouseLeave={() => {
              SetShowHover(false)
            }}
          >
            <Box
              sx={{
                position: "relative",
                "&:after": {
                  content: "''",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  backgroundColor: firstVideo.category.channel.pageLink.slug,
                  opacity: showHover ? 0.8 : 0,
                  transition: "all .2s ease-in-out",
                },
              }}
            >
              <GatsbyImage
                image={firstVideo.thumbnail.desktop}
                alt={firstVideo.title}
              />
              <Box
                sx={{
                  position: "absolute",
                  right: [2],
                  bottom: [2],
                  backgroundColor: showHover
                    ? "light"
                    : firstVideo.category.channel.pageLink.slug,
                  px: [1],
                  py: [1],
                  color: showHover
                    ? firstVideo.category.channel.pageLink.slug
                    : "light",
                  fontSize: ["12px"],
                  fontWeight: "500",
                  transition: "all .2s ease-in-out .05s",
                  zIndex: 99,
                }}
              >
                <Flex sx={{ alignItems: "center" }}>
                  <List size={16} />
                  <Text sx={{ ml: 2, fontSize: 1 }}>
                    {videoCount} {t.videos}
                  </Text>
                </Flex>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  zIndex: 99,
                  transform: "translate(-50%,-50%)",
                  opacity: showHover ? 1 : 0,
                  transition: "all .2s ease-in-out .05s",
                }}
              >
                <PlayCircle size={42} color={light} />
              </Box>
            </Box>
            <Heading
              as="h2"
              variant="h3"
              sx={{
                my: [2],
                color: "dark",
                display: "-webkit-box",
                "-webkit-line-clamp": ["3"],
                "-webkit-boxOrient": "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {playlist.title}
            </Heading>
          </InboundLink>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default PlaylistThumb
